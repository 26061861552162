import React from "react"
import Layout from "../components/Layout/Layout"
import Hero from "../components/Hero/index"
import Products from "../components/Products"
import { productData, productDataTwo } from "../components/Products/data"
import Feature from "../components/Feature"
import Footer from "../components/Footer"

export default function Home() {
  return (
    <Layout>
      <div>
        <Hero />
        <Products heading="Choose your favorite" data={productData} />
        <Feature />
        <Products heading="Sweet Treats for You" data={productDataTwo} />
        <Footer />
      </div>
    </Layout>
  )
}
