import { Link } from "gatsby"
import React, { useState } from "react"
import Navbar from "../Navbar"
import Sidebar from "../Sidebar"
import Cart from "../Cart"
import {
  HeroContainer,
  HeroContent,
  HeroItems,
  HeroH1,
  HeroP,
  HeroBtn,
} from "./HeroElements"

const Hero = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [cartOpen, setCartOpen] = useState(false)

  const toggleHandler = e => {
    setCartOpen(!cartOpen)
  }

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <HeroContainer>
      <Navbar toggle={toggle} toggleHandler={toggleHandler} />
      <Cart cartOpen={cartOpen} toggleHandler={toggleHandler} />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <HeroContent>
        <HeroItems>
          <HeroH1>Greatest Burger And Fried chicken Ever</HeroH1>
          <HeroP>Ready in no time</HeroP>
          <Link to="/menu">
            <HeroBtn>Place Order</HeroBtn>
          </Link>
        </HeroItems>
      </HeroContent>
    </HeroContainer>
  )
}

export default Hero
