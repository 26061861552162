import React from "react"
import { FeatureContainer, FeatureButton } from "./FeatureElements"

const Feature = () => {
  return (
    <FeatureContainer>
      <h1>Deal of the Day</h1>
      <p>4 Pcs Fried Chicken, fries, Coleslaw, Bread, Sauce.</p>
      <FeatureButton>Order Now</FeatureButton>
    </FeatureContainer>
  )
}

export default Feature
